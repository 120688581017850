import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';

export default function SimpleBackdrop() {

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', bgcolor:'#1246A0', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <Box display='flex'
        sx={{flexDirection:'column', justifyContent:'center', alignItems: 'center', gap:'20px'}}
        >
          <CircularProgress color="inherit" />
          <Typography variant='p' component='h3' textAlign='center' color='white'>eManagement</Typography>
        </Box>
      </Backdrop>
    </div>
  );
}