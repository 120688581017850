import * as React from 'react'

const TaskContext = React.createContext(null);

export const TaskContextProvider = ({children})=>{
    const [open, setOpen] = React.useState(false);
    return (
        <TaskContext.Provider value={{open, setOpen}}>{children}</TaskContext.Provider>
    )
}

export const useTaskContext = ()=>{
    return React.useContext(TaskContext);
}