import React, { useEffect, useState } from 'react';
import './styles/App.css';
import {Route, Routes} from 'react-router-dom'
import HomePage from './Homepage.jsx';
import API_URL from './config/api_url';
import SimpleBackdrop from './Component/Fallback.js';
import { EmployerProtectedRoute } from './ProtectedRoutes/EmployerProtectedRoute.jsx';
import { useEmployerAuth } from './Context/AuthContextProvider.jsx';
import {useMutation, useQuery} from 'react-query';
import axios from 'axios';
import { useInOutContext } from './Context/InOutContext.jsx';
import ForgotPassword from './Component/ForgotPassword.jsx';
import EmployeeActivity from './Employers/EmployeeActivity.jsx';

const EmployerHome = React.lazy(()=> import('./Employers/EmployerHome.jsx'));
const EmployerLoginPage = React.lazy(()=> import('./Employers/EmployerLogin.jsx'))
const SingleEmployeeDetails = React.lazy(()=> import('./Employers/SingleEmployeeDetails.jsx'));
const SingleEmployeeEdits = React.lazy(()=> import('./Employers/EmployeeEdits.jsx'));
const AddCalendar = React.lazy(()=> import('./Component/AddCalendar.jsx'));
const AllLogRecords = React.lazy(()=> import('./Employers/LogRecords.jsx'));
const IndividualLogRecords = React.lazy(()=> import('./Employers/IndividualLogs.jsx'));
const LeaveRequests = React.lazy(()=> import('./Employers/LeaveRequests.jsx'));
const EmployeeTasks = React.lazy(()=> import('./Employers/EmployerTasks.jsx'));
const EmployeeLoginPage = React.lazy(()=> import('./Employees/EmployeeLogin.jsx'));
const EmployeeHome = React.lazy(()=> import('./Employees/EmployeeHome.jsx'));
const EmployeeEdits = React.lazy(()=> import('./Employees/EmployeeEdits.jsx'));
const ExplanationModal = React.lazy(()=> import('./Component/ExplanationModal.jsx'));
const TaskModal = React.lazy(()=> import('./Component/TaskModal.jsx'));
const HolidayList = React.lazy(()=> import('./Employees/HolidayList.jsx'));
const MyLeaves = React.lazy(()=> import('./Employees/MyLeaves.jsx'));
const MyLogRecords = React.lazy(()=> import('./Employees/MyLogs.jsx'));
const MyTasks = React.lazy(()=> import('./Employees/MyTasks.jsx'));

function App() {
  const auth = useEmployerAuth();
  // const queryClient = new QueryClient();
  const {userIn, setUserIn} = useInOutContext();
  // const [once, setOnce] = React.useState(false);
  const token = localStorage.getItem("token");

  const closeFunctionalityCheck = async()=>{
    if(!userIn){
      return;
    }
    return axios.get(`${API_URL}/api/inOut/checkBrowserClose`, {
      headers: {
        "Content-Type": 'application/json',
        "auth": `Bearer ${token}`
      }
    })
  }
 
  const {isLoading, refetch} = useQuery('ckeck-close', closeFunctionalityCheck, 
  {
    onSuccess: data=> {
      if(data?.data.isClosed){
        const user = JSON.parse(localStorage.getItem('user'));
        delete user.isLoggedIn;
        localStorage.setItem('user', JSON.stringify(user));
        setUserIn(false);
      }
    }
  }
  )
  const {mutate} = useMutation(async()=>{
    if(!userIn){
      return;
    }
    const resp = await axios.post(`${API_URL}/api/inOut/onPageClose`, 
    {
      logoutTime: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`
    },
    {
      headers: {
        "Content-Type": 'application/json',
        "auth": `Bearer ${token}`
      }
    }
    )
    return resp;
  })

  useEffect(() => {
    if(!userIn){
      return;
    }
    const handleUnload = (event) => {
      if (!event.currentTarget.performance.navigationType) {
        mutate();
      }
    };

    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  return (
      <div className='App'>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/employer-login' element={<React.Suspense fallback={<SimpleBackdrop />}><EmployerLoginPage /></React.Suspense>} />
          <Route path='/employee-login' element={<React.Suspense fallback={<SimpleBackdrop />}><EmployeeLoginPage /></React.Suspense>} />
          <Route path='/reset-password' element={<React.Suspense fallback={<SimpleBackdrop />}><ForgotPassword /></React.Suspense>} />
          <Route element={<EmployerProtectedRoute  auth={auth.validEmployer} />}>
            <Route path='/employer' element={<React.Suspense fallback={<SimpleBackdrop />}><EmployerHome /></React.Suspense>} />
            <Route path='/employee' element={<React.Suspense fallback={<SimpleBackdrop />}><SingleEmployeeDetails /></React.Suspense>}>
              <Route path=':id' element={<React.Suspense fallback={<SimpleBackdrop />}><SingleEmployeeDetails /></React.Suspense>} />
            </Route>
            <Route path='/userActivity/:id' element={<React.Suspense fallback={<SimpleBackdrop />}><EmployeeActivity /></React.Suspense>}/>
            <Route path='/employeeEdits' element={<React.Suspense fallback={<SimpleBackdrop />}><SingleEmployeeEdits /></React.Suspense>}>
              <Route path=':id' element={<React.Suspense fallback={<SimpleBackdrop />}><SingleEmployeeEdits /></React.Suspense>} />
            </Route>
            <Route path='/addCalendar' element={<React.Suspense fallback={<SimpleBackdrop />}><AddCalendar /></React.Suspense>} />
            <Route path='/allLogs' element={<React.Suspense fallback={<SimpleBackdrop />}><AllLogRecords /></React.Suspense>} />
              <Route path='/individualLogs' element={<React.Suspense fallback={<SimpleBackdrop />}><IndividualLogRecords /></React.Suspense>}>
              <Route path=':id' element={<React.Suspense fallback={<SimpleBackdrop />}><IndividualLogRecords /></React.Suspense>} />
            </Route>
            <Route path='/allLeaves' element= {<React.Suspense fallback={<SimpleBackdrop />}><LeaveRequests/></React.Suspense>}/>
            <Route path='/allTasks' element={<React.Suspense fallback={<SimpleBackdrop />}><EmployeeTasks /></React.Suspense>} />
          </Route>
          <Route element={<EmployerProtectedRoute  auth={auth.validEmployee} />}>
            <Route path='/myDetails' element={<React.Suspense fallback={<SimpleBackdrop />}><EmployeeHome activeItem={'home'} /></React.Suspense>} />
            <Route path='/employeeEdit' element={<React.Suspense fallback={<SimpleBackdrop />}><EmployeeEdits activeItem={'profile'}/></React.Suspense>} />
            <Route path='/myHolidays' element={<React.Suspense fallback={<SimpleBackdrop />}><HolidayList/></React.Suspense>}/>
            <Route path='/myLeaves' element={<React.Suspense fallback={<SimpleBackdrop />}><MyLeaves activeItem={'leave'}/></React.Suspense>}/>
            <Route path='/myLogs' element={<React.Suspense fallback={<SimpleBackdrop />}><MyLogRecords activeItem={'logs'}/></React.Suspense>} />
            <Route path='/myTask' element={<React.Suspense fallback={<SimpleBackdrop />}><MyTasks activeItem={'tasks'}/></React.Suspense>}/>
          </Route>
        </Routes>
        <ExplanationModal />
        <TaskModal />
      </div>
  )
}

export default App;
