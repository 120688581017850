import React from "react";
import Calendar from "../Component/Calendar";
import { useState } from "react";
import axios from "axios";
import API_URL from "../config/api_url";
import { useQuery } from "react-query";
import EmployerAppBar from "../Component/EmployerAppbar";
import { useParams } from "react-router";
import { parseDate } from "../lib/utils.js";
import ActivityDrawer from "../Component/ActivityDrawer";
import { format, isSameDay } from "date-fns";
import EventLegend from "../Component/EventLegend";
import { calculateMissingDays } from "../lib/utils";

const EmployeeActivity = () => {
  const { id } = useParams();
  const [events, setEvents] = useState([]);
  const [openActivity, setOpenActivity] = useState(false);
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [employeeName, setEmployeeName] = useState("");
  //will use for paginating logs based on month
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [approvedLeaves, setApprovedLeaves] = useState(0);
  const [missingDays, setMissingDays] = useState(0);
  const [rejectedLeaves,setRejectedLeaves] = useState(0)

  const GetHolidayList = () => {
    const token = localStorage.getItem("token");
    return axios.get(
      API_URL + `/api/data/employeeActivity/${id}?month=${currentMonth}`,{
        headers: {
          "auth": `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      }
    );
  };

  // Helper function to format holidays
  const formatHolidays = (holidays) =>
    holidays.map((holiday) => ({
      title: holiday.occasion,
      start: new Date(holiday.date),
      end: new Date(holiday.date),
      allDay: true,
      type: "holiday",
    }));

  // Helper function to format leaves
  const formatLeaves = (leaves) =>
    leaves.map((leave) => ({
      title: leave.description,
      start: parseDate(leave.startDate),
      end: parseDate(leave.endDate, 23, 59, 59),
      type: "leave",
      status: leave.status,
    }));

  // Helper function to format logs
  const formatLogs = (logs) =>
    logs.flatMap((log) => [
      {
        title: "Login: " + log.login,
        start: new Date(log.createdAt),
        end: new Date(log.createdAt),
        type: "log",
      },
      log.logout &&
        log.logout !== "--:--:--" && {
          title: "Logout: " + log.logout,
          start: new Date(log.updatedAt),
          end: new Date(log.updatedAt),
          type: "log",
        },
      (log.dailyTask || log.explanation) && {
        title:
          (log.dailyTask ? "Daily Task: " : "Explanation: ") +
          (log.dailyTask ?? log.explanation),
        start: new Date(log.updatedAt),
        end: new Date(log.updatedAt),
        type: "log",
      },
    ]);

  // Function to calculate missing days in the current month

  useQuery(["holidays", currentMonth], GetHolidayList, {
    onSuccess: (data) => {
      console.log(data);
      setEmployeeName(data.data.employeeName);
      const formatedHolidays = formatHolidays(
        data.data.holidays?.holidays || []
      );
      const formatedLeaves = formatLeaves(data.data.leaves || []);
      const formatedLogs = formatLogs(data.data.logs || []);

      const allEvents = [
        ...formatedHolidays,
        ...formatedLeaves,
        ...formatedLogs,
      ];
      setEvents(allEvents);
      setApprovedLeaves(data.data?.approvedLeaves);
      setRejectedLeaves(data.data?.rejectedLeaves)
      const missingDaysCount = calculateMissingDays(currentMonth, allEvents);
      setMissingDays(missingDaysCount);
    },
  });

  const eventPropGetter = (event) => {
    if (event?.type === "holiday") {
      return {
        style: {
          backgroundColor: "#3482c8", // Color for holidays
          color: "#fff",
          fontWeight: "normal",
          height: "50px",
        },
      };
    } else if (event?.type === "leave") {
      return {
        style: {
          backgroundColor:
            event.status === "approved"
              ? "green"
              : event.status === "pending"
              ? "#F08080"
              : "red", // Color for leaves
          color: "#fff",
          fontWeight: "normal",
          height: "50px",
        },
      };
    } else if (event.type === "log") {
      return {
        style: {
          backgroundColor: "#f4a261", // Color for login/logout events
          color: "#fff",
          fontWeight: "normal",
        },
      };
    }
    return {};
  };
  const dayPropGetter = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to midnight for comparison

    const isWeekend = date.getDay() === 0 || date.getDay() === 6; // Weekend check
    const hasEvent = events?.some(
      (event) =>
        (date >= new Date(event?.start) && date <= new Date(event?.end)) ||
        isSameDay(date, new Date(event?.start)) // Check if any event matches the current date
    );

    if (date >= today && !isWeekend) {
      // Do not style future dates (no changes)
      return {};
    } else if (isWeekend) {
      // Style for weekends
      return {
        style: {
          backgroundColor: "#cde5d8", // Light green background for weekends
        },
      };
    } else if (!hasEvent) {
      // Style for missing days (no events and not in the future)
      return {
        style: {
          backgroundColor: "#f8d7da", // Light red background for missing days
          border: "1px solid #f5c2c7",
        },
      };
    }

    // Default style for days with events (no changes)
    return {};
  };

  const handleClose = () => {
    setOpenActivity(false);
    setData([]);
  };

  const handleNavigate = (date) => {
    console.log(date);
    setCurrentMonth(date);
  };

  const handleDayClick = ({ start }) => {
    const selectedDayEvents = events.filter(
      (event) => start.toDateString() === new Date(event?.start).toDateString()
    );

    console.log("Events for this day:", selectedDayEvents);
    console.log("Selected date:", start);
    setData(selectedDayEvents);
    setSelectedDate(start);
    setOpenActivity(true);
  };
  return (
    <>
      <EmployerAppBar />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <div style={{ marginRight: "20px", maxWidth: "80%", minWidth: "80%" }}>
          <h1
            style={{
              fontSize: "24px",
              fontWeight: "600",
              color: "#333",
              marginBottom: "20px",
              textAlign: "start",
              marginLeft: 20,
            }}
          >
            <span style={{ fontWeight: "bold", marginRight: "8px" }}>
              Employee Name:
            </span>
            {employeeName}
          </h1>
          <EventLegend />
          <div>
            <Calendar
              events={events}
              eventPropGetter={eventPropGetter}
              handleDayClick={handleDayClick}
              handleNavigate={handleNavigate}
              dayPropGetter={dayPropGetter}
            />
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            width: "350px",
          }}
        >
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "#333",
              marginBottom: "16px",
            }}
          >
            Employee Summary
          </h2>
          <div
            style={{
              borderBottom: "1px solid #e0e0e0",
              paddingBottom: "12px",
              marginBottom: "16px",
            }}
          >
            <strong
              style={{
                fontSize: "16px",
                display: "block",
                marginBottom: "8px",
              }}
            >
              Approved Leaves ({format(new Date(), "yyyy")})
            </strong>
            <span
              style={{ fontSize: "24px", fontWeight: "700", color: "green" }}
            >
              {approvedLeaves}
            </span>
          </div>
          <div
            style={{
              borderBottom: "1px solid #e0e0e0",
              paddingBottom: "12px",
              marginBottom: "16px",
            }}
          >
            <strong
              style={{
                fontSize: "16px",
                display: "block",
                marginBottom: "8px",
              }}
            >
              Rejected Leaves ({format(new Date(), "yyyy")})
            </strong>
            <span
              style={{ fontSize: "24px", fontWeight: "700", color: "red" }}
            >
              {rejectedLeaves}
            </span>
          </div>
          <div
            style={{
              borderBottom: "1px solid #e0e0e0",
              paddingBottom: "12px",
              marginBottom: "16px",
            }}
          >
            <strong
              style={{
                fontSize: "16px",
                display: "block",
                marginBottom: "8px",
              }}
            >
              Missing Days ({format(currentMonth, "MMM yyyy")})
            </strong>
            <span style={{ fontSize: "24px", fontWeight: "700", color: "#f5a0a7" }}>
              {missingDays}
            </span>
          </div>
        </div>
      </div>
      <ActivityDrawer
        open={openActivity}
        handleClose={handleClose}
        data={data}
        selectedDate={selectedDate}
      />
    </>
  );
};

export default EmployeeActivity;
