import React from 'react';

const EventLegend = () => {
  const legendItems = [
    { label: 'Holidays', color: '#3482c8' },
    { label: 'Approved Leaves', color: 'green' },
    { label: 'Pending Leaves', color: '#F08080' },
    { label: 'Rejected Leaves', color: 'red' },
    { label: 'Missing Days', color: '#f8d7da' },
    { label: 'Logins/Logouts', color: '#f4a261' },
    { label: 'Weekends', color: '#cde5d8' },
  ];

  const legendContainerStyle = {
    margin: '20px',
    padding: '10px',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-around', // Spread items evenly
    alignItems: 'center',
    flexWrap: 'wrap', // Wrap to next line if needed
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
  };

  const legendItemStyle = {
    display: 'flex',
    alignItems: 'center',
    margin: '0 5px', // Reduced margin for compactness
  };

  const legendColorBoxStyle = (color) => ({
    width: '15px',
    height: '15px',
    marginRight: '5px',
    borderRadius: '3px',
    backgroundColor: color,
  });

  return (
    <div style={legendContainerStyle}>
      {legendItems.map((item, index) => (
        <div key={index} style={legendItemStyle}>
          <span style={legendColorBoxStyle(item.color)}></span>
          <span style={{ fontSize: '0.9rem' }}>{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default EventLegend;
