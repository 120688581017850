import * as React from 'react'

const InOutContext = React.createContext(null);

export const InOutProvider = ({children})=>{
    const {isLoggedIn} = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false;
    const [userIn, setUserIn] = React.useState(isLoggedIn ? true : false);

    return (
        <InOutContext.Provider value={{userIn, setUserIn}}>{children}</InOutContext.Provider>
    )
}

export const useInOutContext = ()=>{
    return React.useContext(InOutContext)
}