import { createContext, useContext, useState } from "react";
import { useInOutContext } from "./InOutContext";


const EmployerAuth = createContext(null);

export const EmployerAuthProvider = ({ children })=>{
    const user = JSON.parse(localStorage.getItem('user'));
    const [validEmployer, setValidEmployer] = useState(localStorage.getItem("token") && user && user.type === "Employer" ? true : false);
    const [validEmployee, setValidEmployee] = useState(localStorage.getItem('token') && user && user.type === "Employee" ? true : false);
    const {setUserIn} = useInOutContext();
    const isValid = (user)=>{
        setValidEmployer(user);
    }
    const isNotValid = ()=>{
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setValidEmployer(false);
    }
    const isValidEmployee = (user)=>{
        setValidEmployee(user);
    }
    const isNotValidEmployee = ()=>{
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setValidEmployee(false);
        setUserIn(false);
    }
    return <EmployerAuth.Provider value={{validEmployer, isValid, isNotValid, validEmployee, isValidEmployee, isNotValidEmployee}}>{children}</EmployerAuth.Provider>
}

export const useEmployerAuth = ()=>{
    return useContext(EmployerAuth);
}