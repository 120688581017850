import React from "react";
import {
  Drawer,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Box,
  Avatar,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { format } from "date-fns"; // Importing format from date-fns

const ActivityDrawer = ({ open, handleClose, data, selectedDate }) => {
  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor="right"
      PaperProps={{
        sx: { width: 500, padding: 2, backgroundColor: "#f9f9f9" },
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {selectedDate ? selectedDate.toDateString() : "No Date Selected"}
        </Typography>
        <Avatar sx={{ bgcolor: "#3f51b5" }}>
          <EventIcon />
        </Avatar>
      </Box>

      <Divider />

      {/* Events Section */}
      <Box sx={{ mt: 2 }}>
        {data && data.length > 0 ? (
          <List>
            {data.map((event, index) => (
              <ListItem
                key={index}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  mb: 1,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {event.title}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="textSecondary">
                      {event.type === "holiday" || event.type === "leave"
                        ? `Start: ${format(
                            new Date(event.start),
                            "MMMM d, yyyy h:mm a"
                          )} End: ${format(
                            new Date(event.end),
                            "MMMM d, yyyy h:mm a"
                          )}`
                        : `${format(
                            new Date(event.start),
                            "MMMM d, yyyy h:mm a"
                          )}`}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body2" color="textSecondary" align="center">
            No events for this day.
          </Typography>
        )}
      </Box>
    </Drawer>
  );
};

export default ActivityDrawer;
