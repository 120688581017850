import React from "react";
import "../styles/login.css";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import LoginCard from "../Component/LoginCard";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "../Context/SnackbarContext";
import { useMutation } from "react-query";
import axios from "axios";
import API_URL from "../config/api_url";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const ConfirmAlert = ({ severity }) => {
  return (
    <Alert severity={severity}>
      <AlertTitle>{severity.toUpperCase()}</AlertTitle>
      <Typography variant="p" component="h3">
        Password changed successfully
      </Typography>
      <Typography variant="p" component="p">
        You will be redirected to login page
      </Typography>
    </Alert>
  );
};

export default function ForgotPassword() {
  const { openSnackbar } = useSnackbar();
  const [credentials, setCredentials] = React.useState({
    password: "",
    reEntered: "",
  });
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation(
    async ({ credentials, id, type }) => {
      const resp = await axios.put(
        `${API_URL}/api/forgotPassword`,
        {
          id,
          type,
          credentials,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return resp;
    },
    {
      onSuccess: (data) => {
        console.log(data);
        let timer;
        if (data?.data.isReset) {
          setIsSuccess(true);
          timer = setTimeout(() => {
            data?.data.isEmployer
              ? navigate("/employer-login")
              : navigate("/employee-login");
          }, 5000);
        }
        return () => {
          clearTimeout(timer);
        };
      },
      onError: (err) => {
        openSnackbar(err.response.data.error, "error");
      },
    }
  );

  const handleSubmit = () => {
    if (!id || !type) {
      openSnackbar("Follow the link sent to email to reset password!", "error");
      return;
    } else if (!credentials.password || !credentials.reEntered) {
      openSnackbar("Enter credentials", "error");
      return;
    } else if (credentials.password !== credentials.reEntered) {
      openSnackbar("Entered passwords don't match!", "error");
      return;
    }
    mutate({ credentials, id, type });
  };

  return (
    <div>
      <Container>
        <Box
          display="flex"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            marginTop: "5vmin",
          }}
        >
          {isSuccess ? (
            <ConfirmAlert severity="success" />
          ) : (
            <Paper sx={{ padding: "5vmin 10vmin 5vmin 10vmin", border: 1 }}>
              <Container sx={{ marginBottom: "10vmin" }}>
                <img src="/images/AMUS-SOFT-LOGO.png" width="200"></img>
              </Container>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 2 },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  label="Password"
                  sx={{ display: "block", color: "black", fontSize: "18px" }}
                  value={credentials.password}
                  onChange={(e) =>
                    setCredentials({
                      ...credentials,
                      password: e.target.value,
                    })
                  }
                  type="password"
                ></TextField>
                <TextField
                  label="Re-enter Password"
                  sx={{ display: "block" }}
                  value={credentials.reEntered}
                  onChange={(e) =>
                    setCredentials({
                      ...credentials,
                      reEntered: e.target.value,
                    })
                  }
                  type="password"
                  // onKeyDown={(e)=>{
                  //     if(e.key === "Enter"){
                  //     handleLogin()
                  //     }
                  // }}
                ></TextField>
              </Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#1246A0",
                  display: "block",
                  margin: "20px auto",
                  "&:hover": { bgcolor: "purple" },
                }}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                SUBMIT
                {isLoading && (
                  <>
                    &nbsp; <CircularProgress size={10} color="inherit" />
                  </>
                )}
              </Button>
            </Paper>
          )}
        </Box>
      </Container>
      <footer>
        <Box
          display="flex"
          sx={{ justifyContent: "space-evenly", marginTop: "3vmin" }}
        >
          <Container sx={{ marginLeft: "2rem" }}>
            <Container className="address">
              <h3>Head Office</h3>
              <p>Barcelona, Spain</p>
            </Container>
            <Container className="address">
              <h3>India Office</h3>
              <p>Delhi, Chandigarh, India</p>
            </Container>
          </Container>
          <Container sx={{ marginLeft: "3rem" }}>
            <Link to="/about">About</Link>
            <Link to="contact-us">Contact us</Link>
          </Container>
          <Container>
            <img src="/images/AMUS-SOFT-LOGO.png" width="100" />
            <br />
            <Box
              display="flex"
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <Link to="#">
                <i
                  className="bx bxl-twitter"
                  style={{ color: "#1da1f2", fontSize: "50px" }}
                ></i>
              </Link>
              <Link
                to="https://www.linkedin.com/company/amussoft/"
                target="_blank"
              >
                <i
                  className="bx bxl-linkedin-square"
                  style={{ color: "#0077b5", fontSize: "50px" }}
                ></i>
              </Link>
            </Box>
          </Container>
        </Box>
      </footer>
    </div>
  );
}
