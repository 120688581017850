import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { format, parse, startOfWeek, getDay } from "date-fns";
import enUS from "date-fns/locale/en-US";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CalendarComponent = ({
  events,
  eventPropGetter,
  handleDayClick,
  handleNavigate,
  dayPropGetter,
}) => {
  return (
    <div style={{ height: "100vh", padding: 20 }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100%" }}
        defaultView="month"
        dayPropGetter={dayPropGetter}
        eventPropGetter={eventPropGetter}
        selectable
        onSelectSlot={handleDayClick}
        onNavigate={handleNavigate}
      />
    </div>
  );
};

export default CalendarComponent;
