import { Box, Button, Container } from "@mui/material";
import React from "react";
import "./styles/home.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MySvg from "./Component/MySvg";
import { renderToStaticMarkup } from "react-dom/server";
import "./Style/home.css";

export default function HomePage() {
  const svgString = encodeURIComponent(renderToStaticMarkup(<MySvg />));
  const navigate = useNavigate();
  const handleEmployerLogin = () => {
    navigate("/employer-login");
  };
  const handleEmployeeLogin = () => {
    navigate("employee-login");
  };
  return (
    <div
      className="container"
      style={{
        backgroundImage: `url("data:image/svg+xml,${svgString}")`,
        backgroundPosition: "-0.3% 18%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "200px 200px",
      }}
    >
      <div>
        <Box display="flex" sx={{ justifyContent: "space-between" }}>
          <div className="logo-container">
            <Container sx={{ paddingTop: "20px" }}>
              <img src="/images/AMUS-SOFT-LOGO.png" />
            </Container>
          </div>

          <div className="btn-container">
            <Container sx={{ paddingTop: "20px" }}>
              <Button
                variant="contained"
                sx={{ marginRight: "20px", backgroundColor: "#1246A0" }}
                onClick={handleEmployerLogin}
              >
                EMPLOYER
              </Button>
              <Button
                variant="outlined"
                sx={{ color: "#1246A0", border: 1 }}
                onClick={handleEmployeeLogin}
              >
                EMPLOYEE
              </Button>
            </Container>
          </div>
        </Box>
        <Box
          display="flex"
          sx={{
            justifyContent: "space-around",
            alignItems: "center",
            gap: "5vmin",
            marginTop: "5vw",
          }}
        >
          <Container>
            <div className="body-content">
              <Container className="message">
                <h2
                  style={{
                    animationName: "slide-in-left",
                    animationDuration: "1s",
                    animationTimingFunction: "ease-in-out",
                    animationDelay: "0.5s",
                    animationIterationCount: 1,
                    animationDirection: "normal",
                    animationFillMode: "none",
                    transform: "translateX(0)",
                  }}
                >
                  Welcome to eManagement
                </h2>
              </Container>
              <Container sx={{ paddingTop: "20px" }}>
                <p>
                  <strong>eManagement</strong> allows you to store, access, and
                  update various information about your employees, such as their
                  personal details, work hours, payroll, performance, and more.
                  Whether you are a small business owner, a HR manager, or a
                  team leader, an employee management system can help you
                  improve employee satisfaction, productivity, and compliance
                  with legal obligations. It can also help you streamline your
                  HR processes, such as recruitment, training, feedback, and
                  offboarding.
                </p>
              </Container>
            </div>
          </Container>
          <div style={{ width: "500px", marginRight: "20vmin" }}>
            <img src="/images/home_image.webp" width="500" />
          </div>
        </Box>
      </div>

      <div className="footer-section">
        {/* <hr /> */}

        <footer>
          <Box
            display="flex"
            sx={{
              justifyContent: "space-between",
              width: "100vw",
              alignItems: "first-baseline",
              marginLeft: "4vmin",
            }}
          >
            <Container>
              <Box className="address" sx={{ textAlign: "left" }}>
                <h3>Head Office</h3>
                <p>Barcelona, Spain</p>
              </Box>
              <Box className="address">
                <h3>India Office</h3>
                <p>Delhi, Chandigarh, India</p>
              </Box>
            </Container>
            <Container
            // sx={{width:'300px'}}
            >
              <Link to="/about">
                <p>About</p>
              </Link>
              <Link to="contact-us">Contact us</Link>
            </Container>
            <Container sx={{ marginLeft: "10vmin" }}>
              <img src="/images/AMUS-SOFT-LOGO.png" width="100" />
              <br />
              <Box
                display="flex"
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <Link to="#">
                  <i
                    className="bx bxl-twitter"
                    style={{ color: "#1da1f2", fontSize: "50px" }}
                  ></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/amussoft/"
                  target="_blank"
                >
                  <i
                    className="bx bxl-linkedin-square"
                    style={{ color: "#0077b5", fontSize: "50px" }}
                  ></i>
                </Link>
              </Box>
            </Container>
          </Box>
        </footer>
      </div>
    </div>
  );
}
