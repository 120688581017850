import * as React from 'react';
import { createContext } from "react";


const ExplanationContext = createContext(null);

export const ExplanationProvider = ({children})=>{
    const [details, setDetails] = React.useState({
        open: false,
        date: "",
        loginTime: "",
        vacantLogId: ""
    })
    const handleClose = ()=>setDetails({
        open: false,
        date: "",
        loginTime: "",
        vacantLogId: ""
    })
    return (
        <ExplanationContext.Provider value={{details, setDetails, handleClose}}>
            {children}
        </ExplanationContext.Provider>
    )
}

export const useExplanationContext = ()=>{
    return React.useContext(ExplanationContext);
}