import {
  eachDayOfInterval,
  endOfMonth,
  isBefore,
  isSameDay,
  startOfMonth,
} from "date-fns";

export function parseDate(dateString, hours = 0, minutes = 0, seconds = 0) {
  const [day, month, year] = dateString.split("/").map(Number); // Split the date string and convert to numbers
  const date = new Date(year, month - 1, day); // Month is zero-indexed in JavaScript Date, so subtract 1

  // Set the specified time
  date.setHours(hours, minutes, seconds);
  return date;
}

export const calculateMissingDays = (currentMonth, events) => {
  const today = new Date();

  // Get all days in the current month
  const daysInMonth = eachDayOfInterval({
    start: startOfMonth(currentMonth),
    end: endOfMonth(currentMonth),
  });

  // Count the days with no events, excluding weekends, future dates, and today
  const missingDaysCount = daysInMonth.filter((day) => {
    const isWeekend = day.getDay() === 0 || day.getDay() === 6; // Check if the day is Saturday or Sunday
    const hasEvent = events?.some(
      (event) =>
        (day >= new Date(event?.start) && day <= new Date(event?.end)) ||
        isSameDay(day, new Date(event?.start)) // Check if any event matches the current date
    );
    const isPastDate = isBefore(day, today); // Check if the day is before today

    // Include only if it's a past weekday without events
    return !isWeekend && !hasEvent && isPastDate;
  }).length;

  return missingDaysCount;
};
