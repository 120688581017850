import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import { EmployerAuthProvider } from './Context/AuthContextProvider.jsx';
import { SnackbarProvider } from './Context/SnackbarContext.jsx';
import { ExplanationProvider } from './Context/ExplnationModalContext.jsx';
import { InOutProvider } from './Context/InOutContext.jsx';
import { TaskContextProvider } from './Context/TaskContextProvider.jsx';
import {QueryClientProvider, QueryClient} from 'react-query';

const queryClient= new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <InOutProvider>
        <TaskContextProvider>
          <ExplanationProvider>
            <SnackbarProvider>
              <EmployerAuthProvider>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </EmployerAuthProvider>
            </SnackbarProvider>
          </ExplanationProvider>
        </TaskContextProvider>
      </InOutProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
