import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, CircularProgress, TextField } from '@mui/material';
import { useNavigate } from 'react-router';
import API_URL from '../config/api_url';
import { useEmployerAuth } from '../Context/AuthContextProvider';
import { useMutation } from 'react-query';
import axios from 'axios'
import ErrorSanckBar from './ErrorSnackBar';
import { useSnackbar } from '../Context/SnackbarContext';


export default function LoginCard(props) {
  const {openSnackbar} = useSnackbar();
  const [forgot, setForgot] = React.useState(false);
  const [dataHandling, setDataHandling] = React.useState({
    opener: false,
    error: ""
  })
  const [credentials, setCredentials] = React.useState({
    email: "",
    password: "",
  })
  const [urlElem, setUrlElem] = React.useState(props.type === "employer" ? "employerLogin" : "employeeLogin");
  
  const Navigate = useNavigate();
  const auth = useEmployerAuth();

  const handleLogin = ()=>{
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(!regex.test(credentials.email)){
      setDataHandling({
        ...dataHandling,
        error: "Enter a valid email",
        opener: true
      })
      return;
    }
    else if(credentials.password.length<6){
      setDataHandling({
        ...dataHandling,
        error: "Password must be 6 cahracters",
        opener: true
      })
      return;
    }
    console.log(urlElem);
    mutate(credentials)
  }
    const {mutate, isLoading} = useMutation(
      async(credentials)=>{
        const resp = await axios.post(`${API_URL}/api/users/${urlElem}`, 
        {
          email: credentials.email,
          password: credentials.password
        },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
        )
        return resp;
      },
      {
        onSuccess: (data)=>{
          if(props.type==="employer"){
            auth.isValid(true);
            localStorage.setItem("token", JSON.stringify(data?.data.token))
            localStorage.setItem("user", JSON.stringify(data?.data.user));
            Navigate("/employer");
            openSnackbar("Hello Admin" , "success")
          }else{
            auth.isValidEmployee(true);
            localStorage.setItem("token", JSON.stringify(data?.data.token));
            localStorage.setItem("user", JSON.stringify(data?.data.user));
            Navigate("/myDetails")
            openSnackbar("Hello "+JSON.parse(localStorage.getItem("user")).name , "success")
          }
        },
        onError: (error)=>{
          console.log(error);
          setDataHandling({
            ...dataHandling,
            error: error.response.data.error,
            opener: true
          })
        }
      }
    )

    const {mutate: linkMutation, isLoading: isSending} = useMutation(async()=>{
      const resp = await axios.post(`${API_URL}/api/forgotPassword`, 
      {
        email: credentials.email,
        isEmployer: props.type === "employer" ? true : false
      },
      {
        headers: {
          "Content-Type": "application/json",
        }
      }
      )
      return resp;
    },
    {
      onSuccess: data => {
        openSnackbar(data?.data.message, 'success')
      },
      onError: err => {
        console.log(err);
        setDataHandling({
          ...dataHandling,
          error: err.response.data.error,
          opener: true
        })
      }
    }
    )

    const handleForgot = ()=>{
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(!regex.test(credentials.email)){
        setDataHandling({
          ...dataHandling,
          error: "Enter a valid email",
          opener: true
        })
        return;
      }
      linkMutation();
    }

  return (
  <div>
      <>
      <div className='card'>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 2 },
          }}
          noValidate
          autoComplete="off"
          >
          <TextField label='Email'
          sx={{display:'block', color:'black', fontSize:'18px'}}
          value={dataHandling.email}
          onChange={(e)=>setCredentials({
            ...credentials,
            email: e.target.value
          })}
          ></TextField>
          {!forgot && 
          <TextField label='Password'
          sx={{display:'block'}}
          value={dataHandling.password}
          onChange={(e)=>setCredentials({
            ...credentials,
            password: e.target.value
          })}
          type='password'
          onKeyDown={(e)=>{
            if(e.key === "Enter"){
              handleLogin()
            }
          }}
          ></TextField>
          }
          <Box display='flex'
          sx={{justifyContent:'center'}}
          >
          {!forgot ? 
          <Button variant='contained'
          sx={{ backgroundColor:'#1246A0', display:'block', alignContent:'center', '&:hover': {bgcolor:'purple'}}}
          onClick={handleLogin}
          disabled={isLoading}
          >LOG IN{isLoading && <>&nbsp; <CircularProgress size={10} color='inherit' /></>}</Button>
          :
          <Button variant='contained'
          sx={{ backgroundColor:'#1246A0', display:'block', alignContent:'center', '&:hover': {bgcolor:'purple'}}}
          onClick={handleForgot}
          disabled={isSending}
          >SEND LINK{isSending && <>&nbsp; <CircularProgress size={10} color='inherit' /></>}</Button>
          }
          </Box>
          {!forgot && 
          <Button variant='text' sx={{float:'right', textTransform:'none', marginRight:'0px'}}
          onClick={()=>setForgot(true)}
          >Forgot password?</Button>
          }
        </Box>
      </div>
      <ErrorSanckBar dataHandling={dataHandling} setDataHandling={setDataHandling}/>
    </>
  </div>
  )
}
